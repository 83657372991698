<template>
  <div>
    <header
      class="game-header"
      :class="game.color"
      :data-category="game.category"
    >
      <div class="game-header-container">
        <div>
          <p class="game-category">{{ game.category.join(', ') }}</p>
          <h1 class="game-heading">{{ game.title }}</h1>
          <router-link to="/" class="game-back-button">Назад</router-link>
        </div>
        <img
          class="game-avatar"
          :src="`${publicPath}images/games/${game.id}.png`"
          :alt="game.title"
        />
      </div>
    </header>

    <section class="game-breadcrumbs">
      <nav>
        <ul>
          <li>
            <router-link to="/">Каталог</router-link>
          </li>
          <li>
            {{ game.title }}
          </li>
        </ul>
      </nav>
    </section>

    <section class="game-info">
      <h2 class="game-info-heading">Легенда игры</h2>
      <article class="game-info-main">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="game-info-description" v-html="game.fullDescription" />
        <template v-if="game.skills">
          <h3>Затрагиваемые навыки</h3>
          <ul class="list">
            <li v-for="skill in game.skills" :key="skill">{{ skill }}</li>
          </ul>
        </template>
        <ul class="game-info-about">
          <li v-if="game.time" class="time">
            Игра занимает примерно {{ game.time }}
          </li>
          <li v-if="game.people" class="people">
            Нужно {{ game.people }} игроков
          </li>
          <li v-if="game.alreadyPlayed" class="played">
            С нами поиграли уже {{ game.alreadyPlayed }} человек
          </li>
        </ul>
      </article>

      <article class="game-info-form">
        <game-form :game="game" />
      </article>
    </section>

    <div v-if="gameSpeakers.length" class="container">
      <h2>Игру разработали и проводят:</h2>
    </div>
    <speaker-list :speakers="gameSpeakers" />

    <section class="game-how">
      <h2 class="game-how-heading">Как это работает?</h2>
      <img
        class="game-how-image"
        src="../assets/images/how-it-works.jpg"
        alt="Как это работает"
      />
    </section>

    <div class="container">
      <h2>Есть вопросы?</h2>
      <p class="subheading">
        Оставьте заявку, мы свяжемся с Вами и поможем подобрать бизнес-игру
      </p>
    </div>
    <callback-form />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Game',
  data() {
    const { games } = this.$store.state;
    const { gid } = this.$route.params;
    const game = games.find(({ id }) => gid === id);
    if (!game) {
      this.$router.push('/404');
      throw new Error(`Игра с данным gid (${gid}) не найдена`);
    }
    return {
      game,
      gid,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    ...mapState(['speakers', 'games']),
    ...mapGetters(['filteredSpeakers']),
    gameSpeakers() {
      return this.filteredSpeakers.filter(({ id }) => this.game.speakers.includes(id));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/common';

.game-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 56vh;
  padding-bottom: 90px;
  margin-bottom: 80px;
  min-height: 456px;
  background-image: linear-gradient(172.75deg, #8088ff 0%, #5058ce 100%);

  &.blue {
    background-image: linear-gradient(172.75deg, #8088ff 0%, #5058ce 100%);
    .game-back-button {
      color: #5058ce;
    }
  }

  &.orange {
    background-image: linear-gradient(172.75deg, #f5b452 0%, #ce933a 100%);
    .game-back-button {
      color: #ce933a;
    }
  }

  &.liliac {
    background-image: linear-gradient(172.96deg, #cc9bed 0.84%, #a162cc 98.01%);
    .game-back-button {
      color: #a162cc;
    }
  }

  &-container {
    @extend .container;
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 576px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.game-avatar {
  object-fit: contain;
  width: 88px;
  height: 88px;
  margin-bottom: 24px;

  @media (min-width: 576px) {
    width: 132px;
    height: 132px;
  }
}

.game-back-button {
  @extend .button;
  @extend .button_card-for;
}

.game-heading {
  padding: 0;
  margin: 0 0 16px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  color: #ffffff;
}

.game-category {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.game-breadcrumbs {
  @extend .container;
  margin-bottom: 24px;
  ul {
    padding: 0;
  }
  li {
    display: inline-block;
  }
  li > a {
    text-decoration: none;
    color: #2e2e38;
    transition: opacity ease-in 0.2s;
    &:hover,
    &:focus {
      opacity: 0.6;
    }
    &::after {
      content: '>';
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.game-info {
  @extend .container;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'head'
    'main'
    'form';
  row-gap: 32px;
  margin-bottom: 96px;
  color: #2e2e38;

  @media (min-width: 768px) {
    grid-template-columns: minmax(auto, 720px) minmax(auto, 420px);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'head form'
      'main form';
    row-gap: 24px;
    column-gap: 32px;
    justify-content: space-between;
  }

  &-heading {
    grid-area: head;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 0;
  }

  &-main {
    grid-area: main;
  }

  &-description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 32px;

    @media (min-width: 992px) {
      font-size: 20px;
    }
  }

  &-form {
    grid-area: form;
  }

  &-about {
    margin-top: 56px;
    & li {
      background-repeat: no-repeat;
      background-size: 20px 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      padding-left: 30px;
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      &.time {
        background-image: url('../assets/images/time.png');
      }

      &.people {
        background-image: url('../assets/images/people.png');
      }

      &.played {
        background-image: url('../assets/images/played.png');
      }
    }
  }
}

.game-how {
  @extend .container;
  margin-bottom: 72px;
  &-image {
    width: 100%;
  }
  &-heading {
    margin-bottom: 56px;
  }
}
</style>
